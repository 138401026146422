<template>

  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">


      <!-- <div class="container " style="margin: 0 1rem; padding: 0 1rem; border: 1px solid #d8d6de; border-radius: 0.357rem;">
        <p>Select Date range </p>
        
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="fromDate">From:</label>
          <input required v-model="from_date" type="date" class="form-control" id="fromDate">
        </div>
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="toDate">To:</label>
          <input required v-model="to_date" type="date" class="form-control" id="toDate">
        </div>
        
        <div class="form-group" style="display: inline-block; margin-right: 1rem;">
          <label for="status">Select Status:</label>
          <select required v-model="status" class="form-control" id="payment_status" >
            <option value="" disabled selected>Select Status</option> 
            <option value="1">Paid</option>
            <option value="0">Unpaid</option>
          </select>
        </div>
        <button @click.prevent="SearchsettlementListData" class="btn btn-primary"  style=" margin-right: 1rem; border-color: #00cfe8 !important; background-color: #00cfe8 !important;">Submit</button>
        
      </div> -->
      
      
      <table class="w-full border border-gray-300">
        <thead>
          <tr>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Date</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Total Transaction</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Received Amount</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Bank Trans ID</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Status</th>
            <th class="pl-2 border-b border-l border-r border-gray-300 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(settlement, index) in this.settlements" :key="index" class="bg-gray-100">
          <!-- <tr class="bg-gray-100"> -->
          <!-- <tr v-for="(settlement, index) in settlements" :key="index" class="bg-gray-100"> -->
            
            <td class="pl-2 border-b border-l border-r border-gray-300">
              {{ settlement.settlement_from}}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300">
              {{settlement.no_of_transaction}}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300">
              {{ settlement.receivable_amount }}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300">
              {{settlement.bank_trans_id}}
            </td>
            <td class="pl-2 border-b border-l border-r border-gray-300 text-center">
              
              {{ settlement.status === 1 ? 'Draft' : settlement.status === 2 ? 'Paid' : 'Paid' }}
            </td>
            <td  v-if="settlement.id" class="pl-2 border-b border-l border-r border-gray-300">
              <router-link
                :to="{ name: 'admin-settlement-details', params: { settlementId: settlement.id }}"
                target="_blank" 
                class="font-weight-bold"
              >
                view
              </router-link>
                
            </td>   
           
            
            
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
BAvatar,
BPagination,
BFormGroup,
BFormInput,
BFormSelect,
BRow,
BCol,
BLink,
BTooltip,
BMedia,
BBadge,
BButton,
VBToggle,
BSidebar,
BForm,
BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";


import { ISP_LIST, DATE_WISE_REPORT,SETTLEMENT_LIST, TEST} from "@core/services/api";
  export default {
    mixins: [Mixin],
directives: {
  Ripple,
  "b-toggle": VBToggle,
},
components: {
  BCardCode,
  VueGoodTable,
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  BSidebar,
  BForm,
  BFormTextarea,
  ValidationProvider,
  ValidationObserver,
  vSelect,
  flatPickr,
  // eslint-disable-next-line vue/no-unused-components
  ToastificationContent,
},
data() {
  // const statusOptions = [
  //   { value: 2, label: "Paid" },
  //   { value: 1, label: "Unpaid" },
  //   { value: 0, label: "Draft" },
  // ];
  // const resolveClientAvatarVariant = (status) => {
  //   if (status === "Partial Payment") return "primary";
  //   if (status === "Paid") return "danger";
  //   if (status === "Downloaded") return "secondary";
  //   if (status === "Draft") return "warning";
  //   if (status === "Sent") return "info";
  //   if (status === "Past Due") return "success";
  //   return "primary";
  // };
      return {
        
        
        settlements: [],
       
        isp_id:JSON.parse(localStorage.getItem('userData')),
        from_date :"",
        to_date :"",
        status: null,
        total: null,
      }
    },
    methods: {
          SearchsettlementListData() {
          this.$http
              .get(process.env.VUE_APP_BASEURL + DATE_WISE_REPORT, {
              params: {
                
                isp_id: this.isp_id.id,
              },
            })
              .then((res) => {
              console.log('settlement data:',res.data); 
              console.log('hi');
              this.settlements =res.data.data.settlements;
              
              
              })
              .catch((error) => {
              if (error.response.payment_status === 404) {
                  userData.value = undefined;
              }
              });
          },

          settlementListData() {
          this.$http
              .get(process.env.VUE_APP_BASEURL + SETTLEMENT_LIST, {
              params: {
                isp_id: this.isp_id.id,
              },
            })
              .then((res) => {
              console.log('settlement data:',res.data); 
              console.log('settlement data:',this.isp_id.id); 
              console.log('hi');
              this.settlements =res.data.data.settlements;
              
              
              })
              .catch((error) => {
              if (error.response.settlement === 404) {
                  userData.value = undefined;
              }
              });
          },
      },
      mounted() {
        this.settlementListData();

      },
  }
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";


</style>
  <style scoped>
  .error-message {
    color: red;
  }
  </style>